<template>
  <CreateUserLocation
    :location="getUserData.location"
    role="location_user"
  ></CreateUserLocation>
</template>

<script>
import { mapGetters } from "vuex";
import CreateUserLocation from "@/components/users/createUserLocation.vue";

export default {
  name: "RegisterLocationDelegate",
  components: {
    CreateUserLocation,
  },
  computed: {
    ...mapGetters("user", ["getUserData"]),
  },
};
</script>
